import { call, put, takeLatest } from 'redux-saga/effects';
import {
  logout, logoutSuccess, logoutFailed, refreshSessionSuccess, refreshSessionFailed, refreshSession,
} from './Auth.slice';
import { clearAccountData } from 'application/Account/Account.slice';
import { LogoutResponse } from '@qcases/common-ui';
import { authService } from 'domain/services/auth/Auth.service';
// TODO: need to move to common (same logic with phone case app)
function* logoutSaga() {
  try {
    console.log('logoutSaga Logout');
    const logoutResponse: LogoutResponse = yield call(
      authService.logout
    );
    yield put(logoutSuccess(logoutResponse));
  } catch (e) {
    console.log('logoutSaga Logout failed', e);
    yield put(
      logoutFailed((e as unknown as any).message as string)
    );
  }
}

function* refreshSessionSaga() {
  try {
    const refreshedToken: string = yield call(
      authService.refreshAccessToken
    );
    yield put(refreshSessionSuccess(refreshedToken));
  } catch (e) {
    console.log('error refreshing session', e)
    // Detect logged out here
    yield put(
      refreshSessionFailed((e as unknown as any).message as string)
    );
    yield put(
      clearAccountData()
    );
  }
}


export function* authSaga() {
  yield takeLatest(logout.type, logoutSaga);
  yield takeLatest(refreshSession.type, refreshSessionSaga);
}
