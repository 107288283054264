import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HttpRequest } from '../../domain/HttpRequest.model';
import { LogoutResponse } from '@qcases/common-ui';

export interface AuthState {
  apiCall: HttpRequest<LogoutResponse, any>;
  apiCallRefreshSession: HttpRequest<any, any>;
}

const initialState: AuthState = {
  apiCall: {
    loading: true,
    error: null,
    data: null,
  },
  apiCallRefreshSession: {
    loading: false,
    error: null,
    data: null,
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action: PayloadAction) => {
      state.apiCall.loading = true;
    },
    logoutSuccess: (state, action: PayloadAction<LogoutResponse>) => {
      state.apiCall.data = action.payload;
      state.apiCall.loading = false;
    },
    logoutFailed: (state, action: PayloadAction<any>) => {
      state.apiCall.error = action.payload;
      state.apiCall.loading = false;
    },
    refreshSession: (state, action: PayloadAction) => {
      state.apiCallRefreshSession.loading = true;
    },
    refreshSessionSuccess: (state, action: PayloadAction<string>) => {
      state.apiCallRefreshSession.data = action.payload;
      state.apiCallRefreshSession.loading = false;
    },
    refreshSessionFailed: (state, action: PayloadAction<any>) => {
      state.apiCallRefreshSession.error = action.payload;
      state.apiCallRefreshSession.loading = false;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  logout,
  logoutSuccess,
  logoutFailed,
  refreshSession,
  refreshSessionSuccess,
  refreshSessionFailed
} = authSlice.actions;

export const { reducer: authReducer } = authSlice;
