import { spawn } from '@redux-saga/core/effects';
import { addPhoneCaseSaga } from './AddPhoneCase/AddPhoneCase.saga';
import { listingPageSaga } from './ListingPage/ListingPage.saga';
import { accountSaga } from './Account/Account.saga';
import { authSaga } from './Auth/Auth.saga';

export function* productDetailRootSaga() {
  yield spawn(listingPageSaga);
  yield spawn(addPhoneCaseSaga);
  yield spawn(accountSaga);
  yield spawn(authSaga);
}
