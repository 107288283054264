import { BASE_ACCOUNT_URL } from '../../domain/constants/api';
import { Account } from 'domain/Account.model';
import { axiosAuthService } from 'application/AxiosAuth/axiosAuth.service';

async function getAccountDetails() {
  return axiosAuthService
    .getAxiosAuth()
    .get<Account>(`${BASE_ACCOUNT_URL}/external/user-principal/byJwtSub`)
    .then(res => res.data);
}

export { getAccountDetails };
