import { AUTHORIZATION_HEADER, ILogger } from "@qcases/common";
import { AuthService } from "@qcases/common-ui";
import axios, { AxiosInstance } from "axios";
import { logger } from "domain/logger.service";
import { authService } from "domain/services/auth/Auth.service";

export class AxiosAuth {
    private axiosAuth = axios.create();
    constructor(authService: AuthService, logger: ILogger) {
        //we intercept every requests
        this.axiosAuth.interceptors.request.use(async function (config)  {
            logger.debug('Getting access token');
            let accessToken = ''
            try {
                accessToken = await authService.getAccessToken();
                logger.debug('Get access token successfully', accessToken);
            }
            catch(e) {
                logger.debug('Get access token failed', e);
            }
            if (accessToken) {
                logger.debug('Set access token to Authorization header');
                config.headers = config.headers ?? {};
                config.headers[AUTHORIZATION_HEADER] = `Bearer ${accessToken}`;
            }
            return config;
        }, error => {
            return Promise.reject(error)
        })
    }
    public getAxiosAuth(): AxiosInstance {
        return this.axiosAuth;
    }
}

export const axiosAuthService = new AxiosAuth(authService, logger);
