import React, { useCallback, useEffect } from 'react';
import './App.scss';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ProductDetail } from './view/pages/ProductDetail';
import { PhoneCasesPage as PhoneCasesPage } from './view/pages/PhoneCasesPage';
import AppHeader from './view/_components/AppHeader';
import AppFooter from './view/_components/Footer';
import { AddPhoneCase } from './view/pages/AddPhoneCase/AddPhoneCase';
import { SessionManagement } from '@qcases/common-ui';
import { environment } from 'domain/constants/environment';
import { authService } from 'domain/services/auth/Auth.service';
import { refreshSession } from 'application/Auth/Auth.slice';
import { useDispatch } from 'react-redux';

export const App: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('App init');
  }, []);
  const sessionChanged = useCallback((e) => {
    console.log('Session changed', e);
    dispatch(refreshSession());
  }, [dispatch]);
  return (
    <SessionManagement options={
      {
        checkSessionIframePath: '/check_session_iframe',
        idpOrigin: environment.accountApiUrl,
        getSessionIdFn: authService.getSessionId
      }
      }
      sessionChanged={sessionChanged}>
      <>
        <AppHeader />
        <Router>
          <Switch>
            <Route exact path="/">
              <PhoneCasesPage />
            </Route>
            <Route exact path="/phone-cases/add">
              <AddPhoneCase />
            </Route>
            <Route exact path="/phone-cases/:productName">
              <ProductDetail />
            </Route>
          </Switch>
        </Router>
        <AppFooter />
      </>
    </SessionManagement>

  );
};
