import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HttpRequest } from '../../domain/HttpRequest.model';
import { PhoneCase } from 'domain/BackEnd.model';
export interface ListingPageState {
  apiCall: HttpRequest<PhoneCase[], any>;
}

const initialState: ListingPageState = {
  apiCall: {
    loading: true,
    error: null,
    data: null,
  },
};

export const listingPageSlice = createSlice({
  name: 'listingPage',
  initialState,
  reducers: {
    fetchPhoneCases: state => {
      state.apiCall.loading = true;
    },
    fetchPhoneCasesFailed: (state, action: PayloadAction<any>) => {
      state.apiCall.error = action.payload;
      state.apiCall.loading = false;
    },
    fetchPhoneCasesSuccess: (state, action: PayloadAction<any[]>) => {
      state.apiCall.data = action.payload;
      state.apiCall.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchPhoneCases,
  fetchPhoneCasesFailed,
  fetchPhoneCasesSuccess,
} = listingPageSlice.actions;

export const { reducer: listingPageReducer } = listingPageSlice;
