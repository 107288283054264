import React, { useEffect, useRef, useState } from 'react';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBContainer,
  MDBIcon,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'application/root.reducer';
import { CommonSpinner } from './CommonSpinner';
import { CustomButton, urlQueryParamKey } from '@qcases/common-ui';
import { environment } from 'domain/constants/environment';
import { AccountState, fetchAccount } from 'application/Account/Account.slice';
import { logout } from 'application/Auth/Auth.slice';

export default function AppHeader() {
  const [showBasic, setShowBasic] = useState(true);
  const dispatch = useDispatch();
  const { apiCall: {
    data,
    error,
    loading
  } } = useSelector<RootState, AccountState>((state) => state.account);
  const headerAccountRef = useRef();
  useEffect(() => {
    dispatch(fetchAccount());
  }, [dispatch]);
  const onLoginClick = (e) => {
    // Navigate to auth
    console.log('Navigate to auth');
    window.location.href = `${environment.accountAppUrl}?${urlQueryParamKey.CLIENT_ID}=${
      environment.clientId
    }&${urlQueryParamKey.REDIRECT_URI}=${encodeURIComponent(window.location.href)}`;
  };


  const onLogoutClicked = (e) => {
    dispatch(logout());
  }
  return (
    <header>
      <MDBNavbar expand="lg" light bgColor="white">
        <MDBContainer fluid>
          <MDBNavbarToggler
            onClick={() => setShowBasic(!showBasic)}
            aria-controls="navbarExample01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <MDBIcon fas icon="bars" />
          </MDBNavbarToggler>
          <MDBCollapse show={showBasic} className='ms-auto d-flex flex-row'>
            <MDBNavbarNav right className="mb-2 mb-lg-0">
              <MDBNavbarItem active>
                <MDBNavbarLink aria-current="page" href="/phones">
                  Phones
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem active>
                <MDBNavbarLink aria-current="page" href="/">
                  Phone cases
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="#">About</MDBNavbarLink>
              </MDBNavbarItem>
              {
                data ? <MDBNavbarItem style={{ paddingLeft: '30px'}}>
                  <MDBDropdown 
                      ref={headerAccountRef}>
                    <MDBDropdownToggle color='light'
                    className='bg-light bg-gradient'>
                      <img
                        src='https://mdbootstrap.com/img/new/avatars/8.jpg'
                        alt=''
                        style={{ width: '30px', height: '100%' }}
                        className='rounded-circle'
                      />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem header disabled>{data.userName}</MDBDropdownItem>
                      <MDBDropdownItem divider />
                      <MDBDropdownItem link>Account details</MDBDropdownItem>
                      <MDBDropdownItem link>History</MDBDropdownItem>
                      <MDBDropdownItem onClick={onLogoutClicked} link>Log out</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem> : (loading ? <CommonSpinner /> : <CustomButton type='button'
                  loading={false}
                  onClick={onLoginClick}
                >
                  Login
                </CustomButton>)
              }

            </MDBNavbarNav>

          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </header>
  );
}
