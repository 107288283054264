import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HttpRequest } from '../../domain/HttpRequest.model';
import { Account } from 'domain/Account.model';
import { logoutSuccess } from 'application/Auth/Auth.slice';

export interface AccountState {
  apiCall: HttpRequest<Account, any>;
}

const initialState: AccountState = {
  apiCall: {
    loading: true,
    error: null,
    data: null,
  },
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    fetchAccount: (state, action: PayloadAction) => {
      state.apiCall.loading = true;
    },
    fetchAccountSuccess: (state, action: PayloadAction<Account>) => {
      state.apiCall.data = action.payload;
      state.apiCall.loading = false;
    },
    fetchAccountFailed: (state, action: PayloadAction<any>) => {
      state.apiCall.error = action.payload;
      state.apiCall.loading = false;
    },
    clearAccountData: (state) => {
      state.apiCall.data = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, state => {
      state.apiCall.data = null;
    });
  }
});

// Action creators are generated for each case reducer function
export const {
  fetchAccount,
  fetchAccountSuccess,
  fetchAccountFailed,
  clearAccountData
} = accountSlice.actions;

export const { reducer: accountReducer } = accountSlice;
