import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchAccount, fetchAccountFailed, fetchAccountSuccess,
} from './Account.slice';
import { getAccountDetails } from './AccountApi.service';
import { Account } from 'domain/Account.model';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchAccountData(action) {
  try {
    const accountResponse: Account = yield call(
      getAccountDetails
    );
    yield put(fetchAccountSuccess(accountResponse));
  } catch (e) {
    console.log('Fetch account details failed', e);
    yield put(
      fetchAccountFailed((e as unknown as any).message as string)
    );
  }
}
/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
export function* accountSaga() {
  yield takeLatest(fetchAccount.type, fetchAccountData);
}
