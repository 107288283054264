import axios from 'axios';
import { AccessTokenResponse, IAuthApi, LogoutResponse } from '@qcases/common-ui';
import { BASE_ACCOUNT_URL } from 'domain/constants/api';
import { AUTHORIZATION_HEADER, AUTHORIZATION_PREFIX } from '@qcases/common';

class AuthApiService implements IAuthApi {
  public getAccessToken = async () => {
    return axios
      .post<AccessTokenResponse>(`${BASE_ACCOUNT_URL}/auth/token`, {
      }, {
        withCredentials: true
      })
      .then(res => res.data);
  }
  logout(accessToken: string): Promise<LogoutResponse> {
    return axios
      .post<LogoutResponse>(`${BASE_ACCOUNT_URL}/auth/logout`, {
      }, {
        withCredentials: true,
        headers: {
          [AUTHORIZATION_HEADER]: `${AUTHORIZATION_PREFIX} ${accessToken}`
        }
      })
      .then(res => res.data);
  }
}
const authApiService = new AuthApiService();
export { authApiService };
