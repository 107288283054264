import { ILogger } from '@qcases/common';

const logger: ILogger = {
    debug: (message, ...params) => {
        console.log(message, ...params);
    },
    info: (message, ...params) => {
        console.info(message, ...params);
    },
    warn: (message, ...params) => {
        console.warn(message, ...params);
    },
    error: (message, ...params) => {
        console.error(message, ...params);
    },
}

export { logger }
